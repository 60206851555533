import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCalendarAlt, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import logoInicio from './logo_inicio.png';

const Sidebar = () => {
    return (
        <div style={styles.sidebar}>
            <div style={styles.header}>
            <img src={logoInicio} alt="Logo" style={styles.logo} />
            </div>
            <div style={styles.menuItem}>
                <FontAwesomeIcon icon={faHome} style={styles.icon} />
                <span style={{fontWeight: 'bold'}}>Home</span>
            </div>
            <div style={styles.menuItem}>
                <FontAwesomeIcon icon={faCalendarAlt} style={styles.icon} />
                <span style={{fontWeight: 'bold'}}>Check-in</span>
            </div>
            <div style={styles.footer}>
                <FontAwesomeIcon icon={faDoorOpen} style={styles.icon} />
                <span >Cerrar Sesión</span>
            </div>
        </div>
    );
};

const styles = {
    sidebar: {
        width: '280px',
        height: '100%', // Ajustado para ocupar todo el alto
        background: 'linear-gradient(#F600C0, #4409A4)',
        borderTopRightRadius: '40px',
        display: 'flex',
        flexDirection: 'column',
    },
    header: {
        width: '100%',
        height: '154px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        maxWidth: '80%', // o cualquier otro valor para controlar el tamaño del logo
        maxHeight: '80%', 
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        color: 'white',
        cursor: 'pointer',
    },
    icon: {
        marginRight: '10px',
    },
    footer: {
        marginTop: 'auto',
        display: 'flex',
        alignItems: 'center',
        padding: '20px',
        color: 'white',
        cursor: 'pointer',
    },
};


export default Sidebar;
